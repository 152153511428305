import DayJS from './DayJS'
import { setUserPresent } from '../infrastructure/api-present'
import { UserCoursesDTO } from '../interfaces/api-activation'

export const setPresentStatus = (course: UserCoursesDTO) => {
    const { nextClass, status } = course
    if (status !== 'studying') return console.info(`User is not studying, don't require to set present.`)
    if (!nextClass?.open || !nextClass?.end) return console.warn('Course next class info is not available.')

    const isInRange = DayJS().isBetween(DayJS(nextClass.open), DayJS(nextClass.end))

    if (isInRange) {
        setUserPresent(course.courseId)
        console.info('User is in class range, setting present.')
    } else setPresentTimer(course)
}

const setPresentTimer = ({ nextClass, courseId }: UserCoursesDTO) => {
    if (!nextClass) return
    const openTime = DayJS(nextClass.open)
    const fromNow = openTime.diff(DayJS(), 'milliseconds')

    if (fromNow < 0) return
    console.info(`A timer was set for the user to set present at ${openTime.locale('en').format()}, ${openTime.locale('en').fromNow()}`)
    return setTimeout(() => setUserPresent(courseId), fromNow)
}
